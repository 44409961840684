<template>
  <section class="policy-created">
    <div
      class="header-title">
      <h1>
        Policy {{showCreateButton?'Created':'Updated'}}
      </h1>
    </div>
    <el-row
      :gutter="20"
      justify="space-around">

      <el-col
        class="background-white"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="14"
        :xl="14">
        <div class="flex">
          <!--          <span-->
          <!--            v-if="showOtherInsurer"-->
          <!--            class="company-logo">-->
          <!--            <img-->
          <!--              :src="getOptionData('insureOptions',formData.company_id).logo"-->
          <!--              alt="company" />-->
          <!--          </span>-->
          <!--          <span-->
          <!--            v-else-->
          <!--            class="margin-right">Other Insurer</span>-->
          <h2 v-if="formData.product_id==10">Motor Insurance - {{ getOptionName('carClassOptions',formData.class_id) }}</h2>
          <h2 v-else>{{ getOptionName('productNameOptions',formData.product_id) }}</h2>
        </div>
        <div class="table">
          <div
            class="tr">
            <span>Insurer</span>
            <span>{{getOptionName('globalOptions.company',formData.company_id)}}
            </span>
            <!--            <span v-else>Other Insurer</span>-->
          </div>
          <div
            class="tr">
            <span>Insurance Type</span>
            <span>{{getOptionName('userTypeOptions',formData.client_type)}}
            </span>
          </div>
          <div
            class="tr">
            <span>Policyholder</span>
            <span>{{formData.policy_holder_name}}
            </span>
          </div>
          <div
            class="tr">
            <span>Policy Number</span>
            <span>{{formData.policy_no||'-'}}
            </span>
          </div>
          <div
            v-if="formData.product_id==10"
            class="tr">
            <span>Cover Note Number</span>
            <span>{{formData.covernote_no||'-'}}
            </span>
          </div>
        </div>
        <payment-info
          v-if="isShowPayment"
          :form="formData.payment"></payment-info>
      </el-col>
    </el-row>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="14"
      :xl="14">
      <div class="text-end">
        <v-button
          class="button-theme"
          @click="handlerDraftHref(formData.policy_id)">
          View Policy Status
        </v-button>
        <v-button
          v-if="showCreateButton"
          class="button-blue"
          @click="handlerCreate">
          Create Other Policy
        </v-button>
      </div>
    </el-col>
  </section>
</template>

<script>
import {getOrderDetail} from '@api/order'
import MixinOptions from '@/mixins/options'
import MixinFormat from '@/mixins/format'
import paymentInfo from '@/views/components/info/paymentInfo'
export default {
  name: 'policyCreated',
  components:{
    paymentInfo
  },
  mixins: [MixinOptions,MixinFormat],
  data(){
    return{
      formData:{}
    }
  },
  computed:{
    order_no(){
      return this.$route.query.order_no||''
    },
    payment(){
      return this.formData.payment||{}
    },
    showOtherInsurer(){
      let arr =  [1,4,11,3]
      return arr.includes(this.formData.company_id)
    },
    showCreateButton(){
      return this.formData.policy_type===200
    },
    isShowPayment(){
      return this.formData.policy_type!==204||false
    }
  },
  created() {
    this.network().getOrderDetail(this.order_no)
  },
  methods: {
    handlerDraftHref(policy_id) {
      let order_no = this.order_no
      this.$router.push({
        path: '/quotation/premium/info',
        query: {
          order_no
        }
      })
    },
    handlerCreate(){
      this.$router.push('/quotation/createQuoteHome')
    },
    network() {
      return {
        getOrderDetail: async (order_no) => {
          let {data} = await getOrderDetail({order_no: order_no})
          this.formData = data.data||{}
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .policy-created {
    .background-white {
      padding: 15px;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 15px;
    }
    .margin-right{
      margin-right: 10px;
    }

    .text-end {
      text-align: end;
      display: flex;
      justify-content: flex-end;
    }

    .company-logo {
      width: 80px;
      height: 42px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
         width: 100%;
        // max-height: 100%;
        //height: 42px;

      }
    }
    .title{
      background: #718096;
      width: 100%;
      color: #fff!important;
    }
    .header-title{
      color: #000000;
      font-weight: 700;
      width: 100%;
      //padding: 10px 0;
      span{
        font-size: 16px;
      }
      &.flex{
        padding: 10px;
        align-items: center;
        i{
          margin-right: 10px;
        }
      }
      h1{
        color: #000;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size:26px ;
        //border-bottom: 0.5px solid #CBD5E0;
        /*background: #f8f8f8;*/
        border-bottom: 0.5px solid #CBD5E0;
      }
    }
  }
</style>
